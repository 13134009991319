import React from "react";

import cx from "classnames";

import { useFooterQuery } from "@querys/useFooterQuery";
import { Link } from "gatsby";

import SwitchLink from "../links/switchLink";

const Contact = ({ name, phone, email }) => {
  return (
    <div className="mb-10 lg:mb-16">
      <p className="blockH7 mb-6">{name}</p>
      <div className="lg:selection:mb-2">
        <a href={`tel:${phone}`} className="blockH8 hover:text-salmon ">
          {phone}
        </a>
      </div>

      <div>
        <a href={`mailto:${email}`} className="blockH8 hover:text-salmon">
          {email}
        </a>
      </div>
    </div>
  );
};

const ContactBlock = ({ contacts, social }) => {
  return (
    <div className="flex flex-col lg:grid grid-cols-14 text-dark-green pb-20 px-gutter lg:px-0">
      <h1 className="blockH6 col-start-2 col-end-10 py-8">Call Us</h1>

      <div className="col-start-2 col-end-9 flex flex-col lg:grid grid-cols-2">
        {contacts?.map((item, i) => (
          <Contact {...item} />
        ))}
      </div>
      <h1 className="blockH6 col-start-11 col-end-14 row-start-1 py-8">
        Follow
      </h1>
      <div className="blockH8 col-start-11 col-end-14">
        {social?.socialHandles?.map((item, i) => (
          <a
            href={item?.link}
            target="_blank"
            className="mb-3 block hover:text-salmon"
          >
            {item.socialName}
          </a>
        ))}
      </div>
    </div>
  );
};

export const Footer = ({ contactFooter }) => {
  const { footer, social, contact } = useFooterQuery();
  return (
    <div className={cx(" pt-12 lg:pt-28 text-dark-green", {})}>
      {contactFooter && (
        <h1 className="lg:hidden blockH1 mb-10 text-teal px-gutter">Contact</h1>
      )}

      <div
        className={cx(" px-gutter  lg:mb-56", {
          "text-left lg:text-center mb-10": contactFooter,
          "text-center mb-48": !contactFooter,
        })}
      >
        <h1 className="blockH2 mt-6 mb-6">{footer?.callToActionText}</h1>
        {!contactFooter && (
          <Link to="/contact" className="btn-medium ">
            Contact Us
          </Link>
        )}
      </div>
      {contactFooter && (
        <ContactBlock contacts={contact.nodes[1].contacts} social={social} />
      )}
      <div className="mt-10  lg:grid grid-cols-14 pt-10 lg:pt-20  lg:pb-[94px] bg-teal">
        <div className="col-start-2 col-end-7 self-end">
          <h1 className="text-7xl font-slim  text-center lg:text-left">
            <strong className="">am </strong>architects
          </h1>
        </div>
        <div className="py-10 lg:py-0 col-start-7 col-end-14 space-y-4 lg:space-y-0 flex flex-col lg:flex-row items-center text-center lg:text-left lg:justify-between">
          <div className="">
            {footer?.address?.map((item, i) => (
              <p className="mb-2 ">{item}</p>
            ))}
          </div>
          <div>
            {social?.socialHandles?.map((item, i) => (
              <a
                href={item.link}
                target="_blank"
                className="mb-2 block hover:text-salmon"
              >
                {item.socialName}
              </a>
            ))}
          </div>
          <div>
            {footer?.mainLinks?.map((link, i) => (
              <SwitchLink
                {...link}
                key={i}
                className=" text-dark-green hover:text-salmon"
              />
              // <p className="mb-3">{item.linkText}</p>
            ))}
            <p className="my-2">Copyright {new Date().getFullYear()}</p>
            <p className="mb-2">All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import cx from "classnames";
import { useInView } from "react-intersection-observer";

const ModuleWrapper = ({
  children,
  className,
  paddingTop,
  paddingBottom,
  slug,
}) => {
  // Padding Classes
  const paddingClasses = cx(className && className, {
    "pt-space-s": paddingTop === "small",
    "pt-space-m": paddingTop === "medium",
    "pt-space-l": paddingTop === "large",
    "pb-space-s": paddingBottom === "small",
    "pb-space-m": paddingBottom === "medium",
    "pb-space-l": paddingBottom === "large",
  });

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <section
      ref={ref}
      className={cx(
        `${paddingClasses} transition-all ease-in-out duration-700`,
        {
          "opacity-100 md:opacity-0": !inView,
          "opacity-100": inView,
        }
      )}
      id={slug && slug.current}
    >
      {children}
    </section>
  );
};

export default ModuleWrapper;

import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import "@css/tailwind.css";
import "@css/main.css";

import { Header } from "./nav/header";
import { Footer } from "./nav/footer";
import { PageLoader } from "@components/pageLoader";

const Layout = ({
  children,
  reverseHeader,
  hideFooter,
  contactFooter,
  homeNav,
  setReverseHeader,
}) => {
  const breakpoints = useBreakpoint();
  return (
    <>
      <Header
        reverseHeader={
          // reverseHeader
          !contactFooter ? reverseHeader : breakpoints.md ? false : true
        }
        setReverseHeader={setReverseHeader}
        homeNav={homeNav}
      />
      <main className="w-full absolute top-0 left-0">
        {children}
        {!hideFooter && <Footer contactFooter={contactFooter} />}
      </main>
      <PageLoader />
    </>
  );
};

export default Layout;

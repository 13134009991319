import React from "react";

export const Logo = ({
  logoHover,
  className,
  reverseHeader,
  isPinned,
  homeNav,
}) => {
  const fill =
    logoHover && !homeNav
      ? "salmon"
      : reverseHeader || isPinned
        ? "#304856"
        : "white";

  return (
    <svg
      className={`transition-all ease-in-out duration-300 ${className}`}
      width="141"
      height="81"
      viewBox="0 0 141 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_506_1527)">
        <path
          className="transition-all ease-in-out duration-300"
          d="M41.2198 15.0896C41.2052 13.9518 41.155 12.8161 40.9981 11.6825C40.3184 6.74921 36.5831 2.13293 31.823 0.650856C30.2064 0.147033 28.5395 -0.0041139 26.8663 8.46244e-05C19.0172 0.0210772 26.722 8.46245e-05 14.918 0.00428315C14.8197 0.00428315 14.7402 0.0840551 14.7402 0.180621C14.7402 0.262492 14.7967 0.335966 14.8783 0.35276C17.4737 0.942653 20.1508 1.43598 22.3112 3.10699C25.0698 5.21045 26.996 8.42232 27.4206 11.8861C27.6109 13.406 27.6464 14.9279 27.6464 16.4541C27.6464 30.96 27.6527 36.4559 27.6527 50.8379C27.6527 50.9366 27.7322 51.0163 27.8284 51.0163H41.063C41.1613 51.0163 41.2386 50.9366 41.2386 50.84V15.0896H41.2177H41.2198Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M140.982 15.0896C140.967 13.9518 140.917 12.8161 140.76 11.6825C140.08 6.74921 136.345 2.13293 131.585 0.650856C129.968 0.147033 128.301 -0.0041139 126.628 8.46244e-05C118.779 0.0210772 126.484 8.46245e-05 114.68 0.00428315C114.581 0.00428315 114.502 0.0840551 114.502 0.180621C114.502 0.262492 114.558 0.335966 114.64 0.35276C117.235 0.942653 119.912 1.43598 122.073 3.10699C124.832 5.21045 126.758 8.42232 127.182 11.8861C127.373 13.406 127.408 14.9279 127.408 16.4541C127.408 30.96 127.414 36.4559 127.414 50.8379C127.414 50.9366 127.494 51.0163 127.59 51.0163H140.825C140.923 51.0163 141 50.9366 141 50.84V15.0896H140.979H140.982Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M6.84291 51.0164C10.5876 51.0164 13.6233 47.9693 13.6233 44.2106C13.6233 40.4518 10.5876 37.4048 6.84291 37.4048C3.09819 37.4048 0.0625 40.4518 0.0625 44.2106C0.0625 47.9693 3.09819 51.0164 6.84291 51.0164Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M74.8832 51.0163V0.0734863H61.291C61.291 9.16329 61.2952 41.9307 61.2952 51.0163H74.8832Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M108.146 15.0896C108.131 13.9518 108.081 12.8161 107.924 11.6825C107.244 6.74921 103.509 2.13293 98.7488 0.650856C97.1321 0.147033 95.4653 -0.0041139 93.7921 8.46244e-05C85.943 0.0210772 93.6478 8.46245e-05 81.8438 0.00428315C81.7455 0.00428315 81.666 0.0840551 81.666 0.180621C81.666 0.262492 81.7225 0.335966 81.8041 0.35276C84.3995 0.942653 87.0765 1.43598 89.237 3.10699C91.9956 5.21045 93.9218 8.42232 94.3464 11.8861C94.5367 13.406 94.5722 14.9279 94.5722 16.4541C94.5722 30.96 94.5785 36.4559 94.5785 50.8379C94.5785 50.9366 94.658 51.0163 94.7542 51.0163H107.989C108.087 51.0163 108.164 50.9366 108.164 50.84V15.0896H108.144H108.146Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M7.96835 78.6256C7.47268 80.0258 5.79326 80.9747 3.7583 80.9747C1.72334 80.9747 0 79.8117 0 77.5823C0 75.3529 1.60831 74.3788 3.99672 74.0702L7.97044 73.5475V72.7645C7.97044 70.6274 6.66957 69.773 4.89604 69.773C3.1225 69.773 2.05797 70.6757 1.89274 72.1935H0.543772C0.780103 69.8444 2.62474 68.5618 4.89395 68.5618C7.56679 68.5618 9.31523 69.8444 9.31523 72.5735V78.5312C9.31523 79.2428 9.38634 80.0258 9.62267 80.7144H8.20468C8.03946 80.0489 7.96835 79.1945 7.96835 78.6256ZM7.96835 76.0141V74.7315L4.39828 75.1346C2.78997 75.3718 1.41799 75.8 1.41799 77.6033C1.41799 78.8146 2.2692 79.8096 3.92561 79.8096C5.91247 79.8096 7.96835 78.527 7.96835 76.012V76.0141Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M23.8121 69.9159C23.5527 69.8445 23.1261 69.8214 22.8667 69.8214C20.928 69.8214 19.1064 71.4358 19.1064 74.6644V80.7166H17.7344V68.8495H19.0352V71.7443C19.533 69.727 21.0932 68.564 22.8667 68.564C23.1742 68.564 23.5046 68.5871 23.8121 68.6353V69.918V69.9159Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M34.6441 68.5618C37.1036 68.5618 38.783 69.962 39.1616 72.4307H37.8607C37.4592 70.6505 36.3946 69.773 34.6441 69.773C32.0674 69.773 30.6244 71.7904 30.6244 74.7819C30.6244 77.7733 32.0674 79.7907 34.6441 79.7907C36.3946 79.7907 37.4592 78.9132 37.8607 77.133H39.1616C38.783 79.6249 37.1036 81.002 34.6441 81.002C31.4526 81.002 29.2754 78.5815 29.2754 74.784C29.2754 70.9864 31.4505 68.566 34.6441 68.566V68.5618Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M51.9335 68.5618C54.5583 68.5618 56.1185 70.2244 56.1185 73.0479V80.7144H54.7465V73.3334C54.7465 70.9592 53.6819 69.7248 51.6491 69.7248C49.6162 69.7248 48.079 71.0075 48.079 74.1165V80.7144H46.707V63.2444H48.079V71.125C48.6458 69.5107 50.0889 68.5618 51.9335 68.5618Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M64.3457 64.0273H66.0251V66.1161H64.3457V64.0273ZM64.5109 68.8451H65.8829V80.7123H64.5109V68.8451Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M79.4805 80.5717C78.6293 80.8572 77.9433 80.9034 77.5647 80.9034C75.5779 80.9034 74.5133 79.8832 74.5133 77.7713V69.9621H72.7168V68.8473H74.5133V65.7866H75.8853V68.8473H79.1479V69.9621H75.8602V77.8889C75.8602 79.2177 76.5462 79.6921 77.6568 79.6921C78.1064 79.6921 78.6732 79.6208 79.4784 79.3605V80.5717H79.4805Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M91.3545 81C88.0919 81 85.8457 78.6509 85.8457 74.8534C85.8457 71.0558 88.163 68.564 91.2144 68.564C94.6193 68.564 96.0854 71.3644 96.0854 74.3558V75.0213H87.1947C87.2428 78.1072 88.8741 79.7929 91.3566 79.7929C93.1072 79.7929 94.1466 78.9385 94.7134 77.3473H96.0142C95.4475 79.8643 93.4376 81.0021 91.3566 81.0021L91.3545 81ZM87.2407 73.8793H94.7134C94.6193 71.5302 93.5317 69.7731 91.2144 69.7731C89.2276 69.7731 87.5732 71.0789 87.2407 73.8793Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M108.168 68.5618C110.627 68.5618 112.306 69.962 112.685 72.4307H111.384C110.983 70.6505 109.918 69.773 108.168 69.773C105.591 69.773 104.148 71.7904 104.148 74.7819C104.148 77.7733 105.591 79.7907 108.168 79.7907C109.918 79.7907 110.983 78.9132 111.384 77.133H112.685C112.306 79.6249 110.627 81.002 108.168 81.002C104.976 81.002 102.799 78.5815 102.799 74.784C102.799 70.9864 104.974 68.566 108.168 68.566V68.5618Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M125.313 80.5717C124.461 80.8572 123.775 80.9034 123.397 80.9034C121.41 80.9034 120.345 79.8832 120.345 77.7713V69.9621H118.549V68.8473H120.345V65.7866H121.717V68.8473H124.98V69.9621H121.692V77.8889C121.692 79.2177 122.378 79.6921 123.489 79.6921C123.938 79.6921 124.505 79.6208 125.31 79.3605V80.5717H125.313Z"
          fill={fill}
        />
        <path
          className="transition-all ease-in-out duration-300"
          d="M132.972 77.2485C133.137 79.1001 134.794 79.8348 136.567 79.8348C138.341 79.8348 139.688 78.9573 139.688 77.5088C139.688 76.56 139.096 75.8714 137.465 75.5397L135.384 75.1115C133.493 74.7315 132.144 74.0429 132.144 72.0025C132.144 70.1278 133.8 68.5618 136.306 68.5618C138.811 68.5618 140.468 69.6534 140.704 71.8618H139.403C139.238 70.4847 138.125 69.7248 136.306 69.7248C134.486 69.7248 133.422 70.6505 133.422 71.9794C133.422 73.1193 134.179 73.5937 135.645 73.8771L138.01 74.3515C139.855 74.7546 140.991 75.5859 140.991 77.4836C140.991 79.3814 139.146 80.9957 136.569 80.9957C133.993 80.9957 131.841 79.7613 131.65 77.2464H132.974L132.972 77.2485Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_506_1527">
          <rect
            width="141"
            height="81"
            className="transition-all ease-in-out duration-300"
            fill={fill}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from "react";
import PortableText from "react-portable-text";
import cx from "classnames";

import SwitchLink from "@components/links/switchLink";
import { SanityImage } from "@components/sanity/sanityImage";

const SimplePortableText = ({
  text,
  centerText = false,
  className,
  ...props
}) => {
  return text ? (
    <PortableText
      content={text}
      serializers={{
        highlight: (props) => <span {...props} className="text-salmon" />,
        normal: (props) => (
          <p {...props} className=" richH5 mb-6 text-dark-green" />
        ),
        h0: (props) => (
          <h1 {...props} className=" richH0 mb-6 text-dark-green" />
        ),
        h1: (props) => (
          <h1 {...props} className=" richH1 mb-6 text-dark-green" />
        ),
        h2: (props) => (
          <h2 {...props} className="font-display richH2 mb-6 text-dark-green" />
        ),
        h3: (props) => (
          <h3 {...props} className=" richH3 mb-6 text-dark-green" />
        ),
        h4: (props) => (
          <h4 {...props} className=" richH4 mb-6 text-dark-green" />
        ),
        h5: (props) => <h5 {...props} className=" richH5 text-dark-green" />,
        h6: (props) => <h6 {...props} className=" richH6 text-dark-green" />,
        h7: (props) => <h6 {...props} className=" richH7 text-dark-green" />,
        ul: (props) => (
          <ul
            {...props}
            className="list-disc  pl-[2rem] ml-0 text-dark-green"
          />
        ),
        ol: (props) => (
          <ol {...props} className="list-disc pl-[2rem] ml-0 text-dark-green" />
        ),
        link: (props) => (
          <a {...props} className=" richH7 mb-6 text-dark-green underline" />
        ),
        hardBreak: (props) => <span className="h-[10px] block" />,
        pageLink: (props) => (
          <SwitchLink
            {...props}
            className="btn-small transition ease-in-out duration-400"
          />
        ),
        pageAnchorLink: (props) => (
          <SwitchLink
            {...props}
            className="btn-small transition-all ease-in-out duration-400"
          />
        ),
        anchorLink: (props) => (
          <SwitchLink
            {...props}
            className="btn-small transition-all ease-in-out duration-400"
          />
        ),
        figure: (props) => <SanityImage image={props} className="my-20" />,
      }}
      className={cx(className, {
        "portable-text": true,
      })}
      {...props}
    />
  ) : null;
};

export default SimplePortableText;

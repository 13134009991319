import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useNavQuery = () => {
  const { sanitySettingsNav } = useStaticQuery(graphql`
    query {
      sanitySettingsNav {
        mainMenu {
          ...PageLink
        }
      }
    }
  `);
  return sanitySettingsNav || {};
};

import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import { useScrollFreeze } from "@hooks/useScrollFreeze";

import { useNavQuery } from "@querys/useNavQuery";
import { useFooterQuery } from "@querys/useFooterQuery";
import SwitchLink from "../links/switchLink";

const variants = {
  open: {
    opacity: 1,

    transition: {
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const menuVars = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.7,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const Contact = ({ name, phone, email }) => {
  return (
    <div className="text-dark-green mb-10 pt-6">
      <p className="blockH7 mb-6">{name}</p>
      <div className="mb-2">
        <a href={`tel:${phone}`} className="blockH8  ">
          {phone}
        </a>
      </div>

      <div>
        <a href={`mailto:${email}`} className="blockH8">
          {email}
        </a>
      </div>
    </div>
  );
};

const NavInner = ({ navOpen, setNavOpen, links, contacts }) => {
  useScrollFreeze();

  return (
    <motion.div
      key={navOpen}
      //   initial={{ opacity: 0 }}
      //   animate={{ opacity: 1 }}
      //   exit={{ opacity: 0 }}
      initial="closed"
      animate="open"
      exit="closed"
      variants={variants}
      className="fixed top-0 left-0 w-full bg-salmon z-50"
    >
      <div className="h-screen w-full grid items-center ">
        <div className="grid grid-cols-14 ">
          <div className="col-start-2 lg:col-start-3 col-end-14 lg:col-end-10 grid grid-cols-1 gap-y-6 lg:gap-y-4 ">
            {links?.map((item, i) => (
              <motion.div variants={menuVars} className="">
                <SwitchLink
                  {...item}
                  key={i}
                  className="btn-nav transition-all ease-in-out duration-400"
                  onClick={() => setNavOpen(false)}
                />
              </motion.div>
            ))}
          </div>
          <div className="hidden lg:block col-start-11 xl:col-start-10 col-end-14 ">
            <motion.div variants={menuVars}>
              {contacts?.map((item, i) => (
                <Contact {...item} />
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export const NavModal = ({ navOpen, setNavOpen }) => {
  const { mainMenu } = useNavQuery();
  const { contact } = useFooterQuery();
  return (
    <AnimatePresence exitBeforeEnter>
      {navOpen && (
        <NavInner
          navOpen={navOpen}
          links={mainMenu}
          contacts={contact.nodes[1].contacts}
          setNavOpen={setNavOpen}
        />
      )}
    </AnimatePresence>
  );
};

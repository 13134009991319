import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import cx from "classnames";
import Headroom from "react-headroom";
import { useSiteContext } from "@lib/context";

import { Logo } from "./logo";
import { NavModal } from "./navModal";

export const Header = ({ reverseHeader, setReverseHeader, homeNav }) => {
  const breakpoints = useBreakpoint();
  const { state } = useSiteContext();
  const [navOpen, setNavOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [menuHover, setMenuHover] = useState(false);
  const [logoHover, setLogoHover] = useState(false);

  const toggleNav = () => setNavOpen((prev) => !prev);

  useEffect(() => {
    if (homeNav) {
      setTimeout(
        () => {
          setReverseHeader(false);
        },
        state ? 8150 : 1
      );
    }
  }, []);

  return (
    <>
      <Headroom
        style={{ zIndex: "60" }}
        onPin={() => setIsPinned(true)}
        onUnpin={() => setIsPinned(false)}
        onUnfix={() => setIsPinned(false)}
      >
        <nav
          className={cx(
            " w-full flex justify-between items-center px-gutter lg:pl-14 lg:pr-16 py-6 lg:py-12",
            {
              "bg-white": isPinned && !navOpen,
              // "bg-salmon": !isPinned && navOpen,
            }
          )}
        >
          <div
            onMouseEnter={() => setLogoHover(true)}
            onMouseLeave={() => setLogoHover(false)}
          >
            <Link to="/">
              <Logo
                className={cx("w-24 lg:w-36 ", {
                  "opacity-0": navOpen,
                })}
                logoHover={logoHover}
                reverseHeader={reverseHeader}
                isPinned={isPinned}
                homeNav={homeNav}
              />
            </Link>
          </div>
          <div
            onClick={toggleNav}
            className="flex space-x-4 items-center blockH7"
          >
            <span
              className={cx(
                "hidden lg:block transition-all ease-in-out duration-300 text-salmon",
                {
                  "opacity-0 ": !menuHover,
                  "opacity-100 ": menuHover || !breakpoints.lg,
                }
              )}
            >
              Menu
            </span>
            {!breakpoints.md &&
              (navOpen ? (
                <span className="text-dark-green">Close</span>
              ) : (
                <span
                  className={cx("", {
                    "text-dark-green": reverseHeader,
                    "text-white": !reverseHeader,
                  })}
                >
                  Menu
                </span>
              ))}

            {!reverseHeader ? (
              <div
                onMouseEnter={() => setMenuHover(true)}
                onMouseLeave={() => setMenuHover(false)}
                className={cx(
                  "border-2 w-7 h-7 rounded-full cursor-pointer transition-all ease-in-out duration-300",
                  {
                    "bg-salmon border-dark-green": navOpen,
                    "border-salmon": menuHover && !navOpen,
                    "bg-salmon": menuHover || navOpen,
                    "bg-white border-white":
                      !menuHover && !navOpen && !isPinned,

                    "bg-dark-green border-dark-green":
                      isPinned && !menuHover && !navOpen,
                  }
                )}
              />
            ) : (
              <div
                onMouseEnter={() => setMenuHover(true)}
                onMouseLeave={() => setMenuHover(false)}
                className={cx(
                  "border-2 w-7 h-7 rounded-full cursor-pointer transition-all ease-in-out duration-300 ",
                  {
                    "border-salmon": menuHover && !navOpen && !homeNav,
                    "bg-salmon": menuHover || navOpen,
                    "bg-dark-green border-dark-green": !menuHover && !navOpen,

                    "border-dark-green": navOpen,
                  }
                )}
              />
            )}
          </div>
        </nav>
      </Headroom>
      <NavModal navOpen={navOpen} setNavOpen={setNavOpen} />
    </>
  );
};

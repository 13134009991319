import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useFooterQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      footer: sanitySettingsFooter {
        address
        callToActionText
        mainLinks {
          ... on SanityPageLink {
            _key
            _type
            page {
              ... on SanitySimplePage {
                id
                _type
                slug {
                  current
                }
              }
            }
            linkText
          }
        }
      }
      social: sanitySettingsSocial {
        socialHandles {
          key: _key
          socialName
          link
        }
      }
      contact: allSanityContactInfo {
        nodes {
          contacts {
            phone
            name
            email
          }
        }
      }
    }
  `);
  return data || {};
};
